import React, { useContext, useState } from "react";
import ReactCountryFlag from "react-country-flag";
// import { toast } from "react-toastify";
import { MdClose } from "react-icons/md";

import { UserContext } from "../../Providers/UserContext";
import { useTypedDispatch, useTypedSelector } from "../../store/typeHooks";
import { deposit, DepositResponse } from "../../API/payment";
import Spinner from "../Spinner";
import CryptoPayment from "../CryptoPayment";

// styles
import {
  Backdrop,
  CryptoPaymentContainer,
  Flex,
  Input,
  ModalContainer,
  ModalHeader,
  ModalWrapper,
  PaymentTools,
  Tool,
  WithdrawButton,
  AmountSelect,
} from "./styled";

// payment images
import VoucherImg from "../../assets/image/payment/voucher.png";
import oneVoucherImg from "../../assets/image/payment/1Voucher.png";
import kazangVoucherImg from "../../assets/image/payment/Kazang-voucher.png";
import ottVoucherImg from "../../assets/image/payment/OTT-Voucher.png";
import eftImg from "../../assets/image/payment/eft.png";
import creditcardImg from "../../assets/image/payment/credit_card.png";
import snapscanImg from "../../assets/image/payment/snap-scan.png";
import zapperImg from "../../assets/image/payment/zapper.png";
import ETHImg from "../../assets/image/payment/ETH.webp";
import BTCImg from "../../assets/image/payment/BTC.webp";
import USDTImg from "../../assets/image/payment/USDT.webp";
import USDCImg from "../../assets/image/payment/USDC.webp";

interface DepositModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

export default function DepositModal({ isOpen, handleClose }: DepositModalProps) {
  const dispatch = useTypedDispatch();
  const { id } = useTypedSelector((state) => state.auth);
  const [isCrypto, setIsCrypto] = useState(true);
  const [coin, setCoin] = useState("");
  const [paymentUrl, setPaymentUrl] = useState("");
  // const tempAmountList = [
  //   { amount: "15" },
  //   { amount: "25" },
  //   { amount: "50" },
  //   { amount: "100" },
  //   { amount: "250" },
  //   { amount: "500" },
  //   { amount: "other" },
  // ];
  const { currentCountry, countryCode } = useContext(UserContext);
  const [toolIndex, setToolIndex] = useState({
    name: "Cryptocurrency",
    image: "",
    type: "crypto",
  });
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  // const [isShow, setShow] = useState(false);
  // const [select, setSelect] = useState("");

  const payment_list = [
    {
      name: "Bluvoucher",
      image: VoucherImg,
      type: "bluvoucher",
    },
    {
      name: "1Voucher ",
      image: oneVoucherImg,
      type: "onevoucher",
    },
    {
      name: "Kazang Voucher",
      image: kazangVoucherImg,
      type: "kazangvoucher",
    },
    {
      name: "OTT Vouchers",
      image: ottVoucherImg,
      type: "ott_voucher",
    },
    {
      name: "EFT",
      image: eftImg,
      type: "eft",
    },
    {
      name: "Credit Card",
      image: creditcardImg,
      type: "credit_card",
    },
    {
      name: "Snapscan",
      image: snapscanImg,
      type: "snapscan",
    },
    {
      name: "Zapper",
      image: zapperImg,
      type: "zapper",
    },
  ];

  const crypto_payment_list = [
    {
      name: "ETH",
      icon: ETHImg,
    },
    // {
    //   name: "Bitcoin",
    //   icon: BTCImg,
    // },
    {
      name: "Tether",
      icon: USDTImg,
    },
    {
      name: "USDC",
      icon: USDCImg,
    },
  ];

  const handleDeposit = async () => {
    setLoading(true);
    const data = {
      amount: Number(amount),
      payment_type: toolIndex.type,
      customer_reference: id,
      merchant_reference: id,
    };

    await dispatch(deposit(data))
      .then((result) => {
        setLoading(false);
        setPaymentUrl((result.payload as DepositResponse).url);
        // window.open((result.payload as DepositResponse).url, "_self");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleAmount = (value: string) => {
    setAmount(value);
  };

  const handleCrypto = (value: string) => {
    if (amount) {
      setCoin(value);
    }
  };

  // const selectAmount = (value: string) => {
  //   setSelect(value);
  //   if (value === "other") {
  //     setShow(true);
  //     setAmount("");
  //   } else {
  //     setShow(false);
  //     setAmount(value);
  //   }
  // };

  return isOpen ? (
    <>
      <Backdrop />
      <ModalWrapper>
        <ModalContainer style={{ width: "680px", padding: "0" }}>
          {paymentUrl !== "" ? (
            <div style={{ position: "relative", height: "680px" }}>
              <div
                onClick={() => setPaymentUrl("")}
                style={{ width: "100%", display: "flex", alignItems: "flex-end", justifyContent: "flex-end" }}
              >
                <MdClose size={20} color="#0006" style={{ padding: "20px" }} />
              </div>
              <iframe src={paymentUrl} style={{ width: "100%", height: "100%" }} frameBorder="no" />
            </div>
          ) : (
            <Flex direction="row">
              <PaymentTools style={{ height: "640px" }}>
                <Flex alignItems="center" style={{ gap: "10px" }}>
                  <ReactCountryFlag
                    countryCode={countryCode}
                    svg
                    cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                    title={countryCode}
                    style={{ height: "1.5em", width: "1.5em", borderRadius: "50%" }}
                  />
                  {/* </div> */}
                  <div style={{ textAlign: "left" }}>
                    <div style={{ fontSize: "11px", fontWeight: 400, color: "#6a769065" }}>Payment methods for the region</div>
                    <div style={{ fontSize: "11px", fontWeight: 600, color: "#6a7690" }}>{currentCountry}</div>
                  </div>
                </Flex>
                <div style={{ display: "grid", gridTemplateColumns: "auto auto", gap: "10px", paddingTop: "10px" }}>
                  <Tool
                    selected={isCrypto}
                    onClick={() => {
                      setIsCrypto(true);
                      setToolIndex({
                        name: "Cryptocurrency",
                        image: "",
                        type: "crypto",
                      });
                    }}
                  >
                    <div>
                      <nav>
                        {crypto_payment_list.map((item, key) => (
                          <img key={key} src={item.icon} alt={item.name} />
                        ))}
                      </nav>
                      <span>Cryptocurrency</span>
                    </div>
                  </Tool>
                  {payment_list.map((value, key) => (
                    <Tool
                      selected={toolIndex.type === value.type}
                      onClick={() => {
                        setToolIndex(value);
                        setAmount("");
                        setIsCrypto(false);
                        setCoin("");
                      }}
                    >
                      <img src={value.image} alt={value.name} />
                    </Tool>
                  ))}
                </div>
              </PaymentTools>
              <div style={{ padding: "20px", width: "100%" }}>
                <ModalHeader style={{ paddingBottom: "10px" }}>
                  <span style={{ fontSize: "20px", fontWeight: 700 }}>
                    {isCrypto && coin !== "" ? `${coin}(Coming soon)` : `Deposit(${toolIndex.name})`}
                  </span>
                  <button onClick={handleClose}>
                    <MdClose size={15} color="#0006" />
                  </button>
                </ModalHeader>
                {isCrypto && coin !== "" ? (
                  <CryptoPayment coin={coin} amount={amount} handleBack={() => setCoin("")} setPaymentUrl={setPaymentUrl} />
                ) : (
                  <Flex direction="column" style={{ gap: "15px" }}>
                    {toolIndex.type === "crypto" && (
                      <CryptoPaymentContainer>
                        {/* <Flex direction="row" alignItems="center" style={{ gap: "7px" }}>
                          {tempAmountList.map((temp, index) => (
                            <AmountSelect onClick={() => selectAmount(temp.amount)} color={temp.amount === select}>
                              {temp.amount}
                            </AmountSelect>
                          ))}
                        </Flex> */}
                        {/* {isShow && ( */}
                        <Flex
                          direction="row"
                          alignItems="center"
                          style={{ backgroundColor: "#EDF0F7", padding: "0 15px", borderRadius: "10px" }}
                        >
                          <Input
                            type="number"
                            placeholder="Amount"
                            onChange={(e) => handleAmount(e.target.value)}
                            value={amount}
                            style={{ width: "100%", height: "43px", padding: "0 15px 0 10px" }}
                          />
                        </Flex>
                        {/* )} */}
                        <nav>
                          {crypto_payment_list.map((item, key) => (
                            <div key={key} onClick={() => handleCrypto(item.name)}>
                              <img src={item.icon} alt={item.name} />
                              <span>{item.name}</span>
                            </div>
                          ))}
                        </nav>
                      </CryptoPaymentContainer>
                    )}
                    {(toolIndex.type === "eft" ||
                      toolIndex.type === "credit_card" ||
                      toolIndex.type === "snapscan" ||
                      toolIndex.type === "zapper") && (
                      <Flex
                        direction="row"
                        alignItems="center"
                        style={{ backgroundColor: "#EDF0F7", padding: "0 15px", borderRadius: "10px" }}
                      >
                        <Input
                          type="number"
                          placeholder="Amount"
                          onChange={(e) => handleAmount(e.target.value)}
                          value={amount}
                          style={{ width: "100%", height: "43px", padding: "0 15px 0 10px" }}
                        />
                      </Flex>
                    )}

                    {toolIndex.type !== "crypto" &&
                      (loading ? (
                        <WithdrawButton>
                          <Spinner />
                        </WithdrawButton>
                      ) : (
                        <WithdrawButton onClick={handleDeposit}>Deposit</WithdrawButton>
                      ))}
                  </Flex>
                )}
              </div>
            </Flex>
          )}
        </ModalContainer>
      </ModalWrapper>
    </>
  ) : (
    <></>
  );
}

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  CasinoHomeSection,
  CasinoHomeSectionHeader,
  CasinoHomeSectionHeaderLeft,
  CasinoHomeSectionSlider,
  // CasinoHomeSectionSliderItem,
  GameListWrapper,
} from "./styled";
import { UserContext } from "../../Providers/UserContext";
import { HomeSectionGames } from "../Landing/styled";

import ogsGameList from "../../config/gameList.json";
import newgameList from "../../config/newGame.json";

type Props = {
  category: any;
};

const GameList: React.FC<Props> = ({ category }: any) => {

  const navigate = useNavigate();
  const { netentGame, playtechGame, amaticGame, novomaticGame, ainsworthGame, quickspinGame, aristocratGame, egtGame } =
    useContext(UserContext);

  const handleGamePlay = (games: any, provider: string, item?: string) => {
    if (item === "news") {
      navigate(`/casino/play/${games.gameCode}`, { state: { gameItem: games, provider, api: item } });
    } else if (item === "ogs") {
      if (provider === "EGT2" || provider === "ARISTOCRAT2") {
        navigate(`/casino/play/${games.gameId}`, { state: { gameItem: games, provider, api: item } });
      } else {
        navigate(`/casino/play/${games.gameName}`, { state: { gameItem: games, provider, api: item } });
      }
    } else {
      navigate(`/casino/play/${games[1]}`, { state: { gameItem: games, provider } });
    }
  };

  return (
    <React.Fragment>
      <CasinoHomeSection>
        <CasinoHomeSectionHeader>
          <CasinoHomeSectionHeaderLeft>
            <span>{category?.name}</span>
          </CasinoHomeSectionHeaderLeft>
        </CasinoHomeSectionHeader>
        <CasinoHomeSectionSlider>
          <GameListWrapper>
            {category.name === "New Games" &&
              newgameList.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name, "news")}>
                  <div>
                    <img
                      src={`/game-icon/${game.gameCode}.png`}
                      alt={game.gameName}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game.gameName}</span>
                </HomeSectionGames>
              ))}
            {category.name === "NETENT" &&
              netentGame.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${category.path}/${game[1]}.png`}
                      alt={game[2]}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game[2]}</span>
                </HomeSectionGames>
              ))}
            {category.name === "PLAYTECH" &&
              playtechGame.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${category.path}/${game[1]}.png`}
                      alt={game[2]}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game[2]}</span>
                </HomeSectionGames>
              ))}
            {category.name === "AMATIC" &&
              amaticGame.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${category.path}/${game[1]}.png`}
                      alt={game[2]}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game[2]}</span>
                </HomeSectionGames>
              ))}
            {category.name === "NOVOMATIC" &&
              novomaticGame.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${category.path}/${game[1]}.png`}
                      alt={game[2]}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game[2]}</span>
                </HomeSectionGames>
              ))}
            {category.name === "Ainsworth" &&
              ainsworthGame.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${category.path}/${game[1]}.png`}
                      alt={game[2]}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game[2]}</span>
                </HomeSectionGames>
              ))}
            {category.name === "Quickspin" &&
              quickspinGame.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${category.path}/${game[1]}.png`}
                      alt={game[2]}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game[2]}</span>
                </HomeSectionGames>
              ))}
            {category.name === "Aristocrat" &&
              aristocratGame.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${category.path}/${game[1]}.png`}
                      alt={game[2]}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game[2]}</span>
                </HomeSectionGames>
              ))}
            {category.name === "EGT" &&
              egtGame.map((game, index) => (
                <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${category.path}/${game[1]}.png`}
                      alt={game[2]}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game[2]}</span>
                </HomeSectionGames>
              ))}
            {(category.name === "NETENT2" ||
              category.name === "AMATIC2" ||
              category.name === "NOVOMATIC2" ||
              category.name === "ARISTOCRAT2" ||
              category.name === "EGT2" ||
              category.name === "Merkur" ||
              category.name === "PlayNGo" ||
              category.name === "PragmaticPlay" ||
              category.name === "River" ||
              category.name === "Scientific" ||
              category.name === "Yggdrasil" ||
              category.name === "Zeppelin") &&
              ogsGameList
                .filter((item) => item.provider === category.name)
                .map((game, index) => (
                  <HomeSectionGames key={index} onClick={() => handleGamePlay(game, category.name, "ogs")}>
                    <div>
                      <img src={game.iconUrl} alt={game.displayName} width="100%" height="100%" style={{ borderRadius: "10px" }} />
                    </div>
                    <span>{game.displayName}</span>
                  </HomeSectionGames>
                ))}
          </GameListWrapper>
        </CasinoHomeSectionSlider>
      </CasinoHomeSection>
    </React.Fragment>
  );
};

export default GameList;

import React, { useState, useRef } from "react";
import axios from "axios";

import { MdKeyboardArrowLeft } from "react-icons/md";

import Payment from "./payment";

import { Flex } from "../Modals/styled";
import { CryptoPaymentItems, CryptoPaymentItem, CryptoPaymentBtns, BackBtn, ContinueBtn } from "./styled";
import Spinner from "../Spinner";

interface CryptoPaymentProps {
  coin: string;
  amount: string;
  handleBack: () => void;
  setPaymentUrl: (paymentUrl: string) => void;
}

const tether_list = ["ERC-20", "TRC-20"];

const CryptoPayment = ({ coin, amount, handleBack, setPaymentUrl }: CryptoPaymentProps) => {
  const paymentRef = useRef<HTMLInputElement>(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [qrData, setQrData] = useState("");
  const [isContinue, setIsContinue] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const address = "bc1q2cqmzhk06sjpk7hev0nr53h7cdceupk0u9y4sr";

  const handleOptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSelectedOption(value);
  };

  const handleItem = (item: string) => {
    setSelectedOption(item);

    let data = "";
    if (item === "bitcoin") {
      data = `bitcoin:${address}?amount=${amount}`;
    }
    if (item === "ether") {
      data = `ethereum:${address}?value=${amount}`;
    }
    if (item === "usdc-ERC-20") {
      data = `solana:${address}?amount=${amount}`;
    }
    if (item === "tether-ERC-20") {
      data = `ethereum:${address}?value=${amount}`;
    }
    if (item === "tether-Polygon") {
      data = `solana:${address}?value=${amount}`;
    }
    if (item === "tether-TRC-20") {
      data = `tron:${address}?amount=${amount}`;
    }
    setQrData(data);
  };

  const handleContinue = async () => {
    console.log(selectedOption, amount);
    setLoading(true);
    let currency = "";
    if (selectedOption === "ether") {
      currency = "ETH";
    }
    if (selectedOption === "tether-ERC-20") {
      currency = "USDT";
    }
    if (selectedOption === "tether-TRC-20") {
      currency = "USDT_TRX";
    }
    if (selectedOption === "usdc-ERC-20") {
      currency = "USDC";
    }

    // setIsContinue(true);
    await axios
      .get(
        `https://plisio.net/api/v1/invoices/new?currency=${currency}&amount=${amount}&order_name=usdt112&order_number=112&api_key=lwc3LtLYg97N_O6DJKmq4IsFUKmu0gmVZQr3L2tkIckCeuIzXhAPM7lt6DdJyLxp`
      )
      .then((res) => {
        console.log(res.data);
        if (res.data.status === "success") {
          setPaymentUrl(res.data.data.invoice_url);
          setLoading(false);
        }
      });
  };

  return (
    <React.Fragment>
      {isContinue ? (
        <Payment coin={coin} qrData={qrData} network={selectedOption} />
      ) : (
        <Flex direction="column" style={{ justifyContent: "space-between", color: "#000", height: "90%" }}>
          <CryptoPaymentItems>
            {coin === "Bitcoin" && (
              <CryptoPaymentItem onClick={() => handleItem("bitcoin")}>
                <div>
                  <p>{coin}(Bitcoin Blockchain)</p>
                  <span>Transaction time ≈ 15 minutes</span>
                </div>
                <input
                  type="radio"
                  value="bitcoin"
                  name="coin"
                  id="bitcoin"
                  ref={paymentRef}
                  checked={selectedOption === "bitcoin"}
                  onChange={handleOptionChange}
                />
              </CryptoPaymentItem>
            )}
            {coin === "ETH" && (
              <CryptoPaymentItem onClick={() => handleItem("ether")}>
                <div>
                  <p>{coin}(ERC-20)</p>
                  <span>Transaction time ≈ 10 minutes</span>
                </div>
                <input
                  type="radio"
                  value="ether"
                  name="coin"
                  id="ether"
                  ref={paymentRef}
                  checked={selectedOption === "ether"}
                  onChange={handleOptionChange}
                />
              </CryptoPaymentItem>
            )}
            {coin === "USDC" && (
              <CryptoPaymentItem onClick={() => handleItem("usdc-ERC-20")}>
                <div>
                  <p>{coin}(ERC-20)</p>
                  <span>Transaction time ≈ 5 minutes</span>
                </div>
                <input
                  type="radio"
                  value="usdc"
                  name="coin"
                  id="usdc"
                  ref={paymentRef}
                  checked={selectedOption === "usdc-ERC-20"}
                  onChange={handleOptionChange}
                />
              </CryptoPaymentItem>
            )}
            {coin === "Tether" &&
              tether_list.map((item, index) => (
                <CryptoPaymentItem key={index} onClick={() => handleItem(`tether-${item}`)}>
                  <div>
                    <p>
                      {coin}({item})
                    </p>
                    <span>Transaction time ≈ 5 minutes</span>
                  </div>
                  <input
                    type="radio"
                    value={item}
                    name="coin"
                    id={item}
                    ref={paymentRef}
                    checked={selectedOption === `tether-${item}`}
                    onChange={handleOptionChange}
                  />
                </CryptoPaymentItem>
              ))}
          </CryptoPaymentItems>
          <CryptoPaymentBtns>
            <BackBtn onClick={handleBack}>
              <MdKeyboardArrowLeft size={20} />
            </BackBtn>
            {selectedOption !== "" &&
              (!isLoading ? (
                <ContinueBtn onClick={handleContinue}>Continue</ContinueBtn>
              ) : (
                <ContinueBtn>
                  <Spinner />
                </ContinueBtn>
              ))}
          </CryptoPaymentBtns>
        </Flex>
      )}
    </React.Fragment>
  );
};

export default CryptoPayment;

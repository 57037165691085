import { createAsyncThunk } from "@reduxjs/toolkit";
import api, { ErrorType } from ".";
import { toast } from "react-toastify";

type DepositType = {
  customer_reference: string;
  merchant_reference: string;
  payment_type: string;
};

export type DepositResponse = {
  key: string;
  url: string;
  origin: string;
};

type WithdrawType = {
  amount: string;
  customer_name: string;
};

export type WithdrawResponse = {
  key: string;
  url: string;
};

export const routes = {
  deposit: "/api/payment/deposit",
  withdtaw: "/api/payment/withdraw",
};

export const deposit = createAsyncThunk<
  DepositResponse,
  DepositType,
  {
    rejectValue: ErrorType;
  }
>("/payment/deposit", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.deposit}`, data);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

export const withdraw = createAsyncThunk<
  WithdrawResponse,
  WithdrawType,
  {
    rejectValue: ErrorType;
  }
>("/payment/withdraw", async (data, { rejectWithValue }) => {
  try {
    const response = await api.post(`${routes.withdtaw}`, data);
    return response.data;
  } catch (e: any) {
    return rejectWithValue({
      message: e.message,
    } as ErrorType);
  }
});

import React, { useContext, useState } from "react";

import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";

import { UserContext } from "../../Providers/UserContext";
import { useTypedDispatch, useTypedSelector } from "../../store/typeHooks";
import { deposit, DepositResponse } from "../../API/payment";

import Header from "../../layout/Header";
import Spinner from "../../components/Spinner";
import { Flex, Input, Tool, WithdrawButton } from "../../components/Modals/styled";

import VoucherImg from "../../assets/image/payment/voucher.png";
import oneVoucherImg from "../../assets/image/payment/1Voucher.png";
import kazangVoucherImg from "../../assets/image/payment/Kazang-voucher.png";
import ottVoucherImg from "../../assets/image/payment/OTT-Voucher.png";
import eftImg from "../../assets/image/payment/eft.png";
import creditcardImg from "../../assets/image/payment/credit_card.png";
import snapscanImg from "../../assets/image/payment/snap-scan.png";
import zapperImg from "../../assets/image/payment/zapper.png";

const payment_list = [
  {
    name: "Bluvoucher",
    image: VoucherImg,
    type: "bluvoucher",
  },
  {
    name: "1Voucher ",
    image: oneVoucherImg,
    type: "onevoucher",
  },
  {
    name: "Kazang Voucher",
    image: kazangVoucherImg,
    type: "kazangvoucher",
  },
  {
    name: "OTT Vouchers",
    image: ottVoucherImg,
    type: "ott_voucher",
  },
  {
    name: "EFT",
    image: eftImg,
    type: "eft",
  },
  {
    name: "Credit Card",
    image: creditcardImg,
    type: "credit_card",
  },
  {
    name: "Snapscan",
    image: snapscanImg,
    type: "snapscan",
  },
  {
    name: "Zapper",
    image: zapperImg,
    type: "zapper",
  },
];

const Deposit: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { id } = useTypedSelector((state) => state.auth);
  const [toolIndex, setToolIndex] = useState(payment_list[0]);
  const { currentCountry, countryCode } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");

  const handleDeposit = async () => {
    setLoading(true);
    const data = {
      amount: Number(amount),
      payment_type: toolIndex.type,
      customer_reference: id,
      merchant_reference: id,
    };

    await dispatch(deposit(data))
      .then((result) => {
        setLoading(false);
        window.open((result.payload as DepositResponse).url, "_self");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleAmount = (value: string) => {
    setAmount(value);
  };

  return (
    <React.Fragment>
      <Header />
      <Flex direction="column" style={{ padding: "15px", textAlign: "left" }}>
        <Flex direction="row" alignItems="center" style={{ marginBottom: "8px" }}>
          <Flex
            direction="row"
            alignItems="center"
            style={{ gap: "6px", margin: "5px 0", cursor: "pointer", flex: 1 }}
            onClick={() => navigate("/profile")}
          >
            <AiOutlineLeft style={{ width: "13px", height: "13px" }} />
            <div style={{ fontSize: "13px" }}>Profile</div>
          </Flex>
          <div style={{ fontSize: "14px", fontWeight: 600 }}>Deposit</div>
          <div style={{ flex: 1 }} />
        </Flex>
        <div style={{ backgroundColor: "white", padding: "20px", borderRadius: "16px" }}>
          <Flex alignItems="center" style={{ gap: "10px", marginBottom: "15px" }}>
            <div style={{ width: "18px", height: "18px", borderRadius: "50%" }}>
              <ReactCountryFlag
                countryCode={countryCode}
                svg
                cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                title={countryCode}
                style={{ height: "1.5em", width: "1.5em", borderRadius: "50%" }}
              />
            </div>
            <Flex direction="column" style={{ textAlign: "left", gap: "5px" }}>
              <div style={{ fontSize: "11px", fontWeight: 400, color: "#6a769065" }}>Payment methods for the region</div>
              <div style={{ fontSize: "11px", fontWeight: 600, color: "#6a7690" }}>{currentCountry}</div>
            </Flex>
          </Flex>
          <div style={{ fontSize: "9px", color: "#090f1e", opacity: 0.3 }}>All methods</div>
          <div style={{ display: "grid", gridTemplateColumns: "auto auto auto", gap: "10px", paddingTop: "10px" }}>
            {payment_list.map((value, key) => (
              <Tool
                selected={toolIndex.type === value.type}
                onClick={() => {
                  setToolIndex(value);
                  setAmount("");
                }}
              >
                <img src={value.image} alt={value.name} />
              </Tool>
            ))}
          </div>
          {(toolIndex.type === "eft" ||
            toolIndex.type === "credit_card" ||
            toolIndex.type === "snapscan" ||
            toolIndex.type === "zapper") && (
            <Flex
              direction="row"
              alignItems="center"
              style={{ backgroundColor: "#EDF0F7", padding: "0 15px", borderRadius: "10px", marginTop: "15px" }}
            >
              <Input
                type="number"
                placeholder="Amount"
                onChange={(e) => handleAmount(e.target.value)}
                value={amount}
                style={{ width: "100%", height: "43px", padding: "0 15px 0 10px" }}
              />
            </Flex>
          )}
          <Flex direction="column" style={{ paddingTop: "15px" }}>
            {loading ? (
              <WithdrawButton>
                <Spinner />
              </WithdrawButton>
            ) : (
              <WithdrawButton onClick={handleDeposit}>Deposit</WithdrawButton>
            )}
          </Flex>
        </div>
      </Flex>
    </React.Fragment>
  );
};

export default Deposit;

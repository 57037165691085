import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  CasinoHome,
  CasinoHomeSection,
  CasinoHomeSectionHeader,
  CasinoHomeSectionHeaderLeft,
  CasinoHomeSectionHeaderRight,
  CasinoHomeSectionSlider,
  CasinoHomeSectionSliderItem,
} from "./styled";
import {
  MobileHomeContainer,
  MobileHomeSectionContainer,
  HomeSectionHeader,
  HomeSectionHeaderTitle,
  HomeSectionHeaderContent,
  HomeSectionGameList,
  HomeSectionGames,
} from "../Landing/styled";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode } from "swiper/modules";
import { UserContext } from "../../Providers/UserContext";
import ogsGameList from "../../config/gameList.json";
import newGameList from "../../config/newGame.json";

interface Game {
  gameCode: string;
  gameId?: string;
  gameName?: string;
  displayName?: string;
  provider?: string;
  iconUrl?: string;
  path?: string;
}

interface Category {
  name: string;
  path: string;
}

interface Props {
  categories: Category[];
  category: string;
  setCategory: (category: string) => void;
}

const CategoryList: React.FC<Props> = ({ categories, category, setCategory }) => {
  const navigate = useNavigate();
  const { netentGame, playtechGame, amaticGame, novomaticGame, ainsworthGame, quickspinGame, aristocratGame, egtGame } =
    useContext(UserContext);

  const gameProviderMapping: Record<string, Game[]> = {
    NETENT: netentGame,
    PLAYTECH: playtechGame,
    AMATIC: amaticGame,
    NOVOMATIC: novomaticGame,
    Ainsworth: ainsworthGame,
    Quickspin: quickspinGame,
    Aristocrat: aristocratGame,
    EGT: egtGame,
  };

  const handleGamePlay = (game: any, provider: string, api?: string) => {
    const gameIdentifier =
      api === "news"
        ? game.gameCode
        : api === "ogs"
        ? game.provider === "EGT2" || game.provider === "ARISTOCRAT2"
          ? game.gameId
          : game.gameName
        : game[1];
    navigate(`/casino/play/${gameIdentifier}`, { state: { gameItem: game, provider, api } });
  };

  const renderGameSliders = (games: any, provider: string) => {
    return games.slice(0, 12).map((game: any, index: number) => (
      <SwiperSlide key={index} onClick={() => handleGamePlay(game, provider)}>
        <CasinoHomeSectionSliderItem>
          <img
            src={`https://eu-static.casinomodules.co/images/${game.path}/${game[1]}.png`}
            alt={provider}
            width="100%"
            height="100%"
            style={{ borderRadius: "16px" }}
          />
        </CasinoHomeSectionSliderItem>
      </SwiperSlide>
    ));
  };

  return (
    <React.Fragment>
      <CasinoHome>
        {categories.map((item: Category, key: number) => (
          <CasinoHomeSection key={key}>
            <CasinoHomeSectionHeader>
              <CasinoHomeSectionHeaderLeft>
                <span>{item.name}</span>
                <label>{gameProviderMapping[item.name]?.length || 0}</label>
              </CasinoHomeSectionHeaderLeft>
              <CasinoHomeSectionHeaderRight>
                <span onClick={() => setCategory(item.name)}>All</span>
              </CasinoHomeSectionHeaderRight>
            </CasinoHomeSectionHeader>
            <CasinoHomeSectionSlider>
              <Swiper
                slidesPerView={4}
                spaceBetween={15}
                className="mySwiper"
                freeMode={true}
                modules={[FreeMode]}
                breakpoints={{
                  1630: { slidesPerView: 7, spaceBetween: 15 },
                  1440: { slidesPerView: 6, spaceBetween: 15 },
                  1250: { slidesPerView: 5, spaceBetween: 15 },
                }}
              >
                {item.name === "New Games"
                  ? renderGameSliders(newGameList, "news")
                  : renderGameSliders(gameProviderMapping[item.name] || [], item.name)}
                {item.name.includes("2") &&
                  ogsGameList
                    .filter((game) => game.provider === item.name)
                    .map((game, index) => (
                      <SwiperSlide key={index} onClick={() => handleGamePlay(game, item.name, "ogs")}>
                        <CasinoHomeSectionSliderItem>
                          <img src={game.iconUrl} alt={item.name} width="100%" height="100%" style={{ borderRadius: "16px" }} />
                        </CasinoHomeSectionSliderItem>
                      </SwiperSlide>
                    ))}
              </Swiper>
            </CasinoHomeSectionSlider>
          </CasinoHomeSection>
        ))}
      </CasinoHome>
      <MobileHomeContainer>
        {categories.map((item: Category, key: number) => (
          <MobileHomeSectionContainer key={key}>
            <HomeSectionHeader>
              <HomeSectionHeaderTitle>{item.name}</HomeSectionHeaderTitle>
              <HomeSectionHeaderContent>
                <label onClick={() => setCategory(item.name)}>All</label>
                <span>{gameProviderMapping[item.name]?.length || 0} games</span>
              </HomeSectionHeaderContent>
            </HomeSectionHeader>
            <HomeSectionGameList>
              {gameProviderMapping[item.name]?.slice(0, 12).map((game: any) => (
                <HomeSectionGames key={game.gameId} onClick={() => handleGamePlay(game, item.name)}>
                  <div>
                    <img
                      src={`https://eu-static.casinomodules.co/images/${game.path}/${game[1]}.png`}
                      alt={item.name}
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px" }}
                    />
                  </div>
                  <span>{game.displayName || game.gameName}</span>
                </HomeSectionGames>
              ))}
              {item.name.includes("2") &&
                ogsGameList
                  .filter((game) => game.provider === item.name)
                  .map((game, index) => (
                    <HomeSectionGames key={index} onClick={() => handleGamePlay(game, item.name, "ogs")}>
                      <div>
                        <img src={game.iconUrl} alt={item.name} width="100%" height="100%" style={{ borderRadius: "10px" }} />
                      </div>
                      <span>{game.displayName}</span>
                    </HomeSectionGames>
                  ))}
            </HomeSectionGameList>
          </MobileHomeSectionContainer>
        ))}
      </MobileHomeContainer>
    </React.Fragment>
  );
};

export default CategoryList;
